import React, { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import "./layout.css";

const links: { path: string; title: string }[] = [
  { path: "/", title: "Home" },
  { path: "/posts", title: "Posts" },
  { path: "/categories", title: "Categories" },
  { path: "/about", title: "About" },
];

type PropType = {
  noContentPadding?: boolean;
  children: React.ReactNode;
};

function Layout({ children, noContentPadding }: PropType) {
  const location = useLocation();
  const mainClass = `container px-0 pb-5 ${
    noContentPadding !== true ? "pt-3" : ""
  }`;
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="bg-primary" style={{ flex: "0 1 auto" }}>
        <nav className="container navbar navbar-expand-lg navbar-dark">
          <Link className="navbar-brand" to="/">
            goof by Patrick
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setCollapsed(!collapsed)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`navbar-collapse${collapsed ? " collapse" : ""}`}>
            <ul className="navbar-nav ms-auto py-4 py-lg-0">
              {links.map((l) => {
                const linkClass =
                  location?.pathname === l.path ? " border-bottom" : "";
                return (
                  <li className="nav-item" key={l.path}>
                    <Link
                      to={l.path}
                      className={`nav-link mx-lg-3 text-white ${linkClass}`}
                    >
                      {l.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
      <main className={mainClass} style={{ flex: "1 1 auto" }}>
        {children}
      </main>
    </div>
  );
}

export default Layout;
