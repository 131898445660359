import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type PropType = {
  post: {
    title: string;
    path: string;
    titleKeyword?: string;
    description?: string;
    image?: string;
    createdDate?: string;
  };
};

const SEO = ({ post }: PropType) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          titleTemplate
          baseUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.baseUrl === "" && typeof window !== "undefined") {
    defaults.baseUrl = window.location.origin;
  }

  if (defaults.baseUrl === "") {
    console.error("Please set a baseUrl in your site metadata!");
    return null;
  }

  const mainTitle =
    post.titleKeyword && post.title
      ? `${post.title} in ${post.titleKeyword}`
      : post.title || defaults.title;
  const titleTemplate = defaults.titleTemplate;
  const description = post.description || defaults.description;
  const url = new URL(post.path || "", defaults.baseUrl);
  const image = post.image ? new URL(post.image, defaults.baseUrl) : false;
  const creDateIso = post.createdDate
    ? new Date(post.createdDate).toISOString().replace(/\.[0-9]{3}/, "")
    : null;

  return (
    <Helmet title={mainTitle} titleTemplate={titleTemplate}>
      <link rel="canonical" href={url.toString()} />
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image.toString()} />}

      <meta property="og:url" content={url.toString()} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={mainTitle} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image.toString()} />}
      {creDateIso && (
        <meta property="article:published_time" content={creDateIso} />
      )}
    </Helmet>
  );
};

export default SEO;
